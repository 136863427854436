<template>
    <div class="upload-demo">
      <el-upload
		:http-request="postImage"
        :action="up_url"
        :data="chunkData"
        :headers="headers"
        :on-success="handleSuccess"
        :chunk-size="1024 * 1024 * 3"
        :thread="4"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
	  <div>
	    <el-progress :text-inside="true" :stroke-width="18" v-if="percentage" :percentage="percentage"
	                 v-show="isProgressVis"></el-progress>
	  </div>
    </div>
</template>

<script>
import YlUpload from './upload'
import {baseURL} from "@/api/https.js";
import {oss} from "@/api/oss.js";
import OSS from 'ali-oss'
export default {
  components:{YlUpload},
  props: {
    msg: String
  },
  data(){
    return{
			  lists:[],
			  tableDatas:[],
			  operation:[],
			 up_url:baseURL+'/backend/uploadsfile/upload_image',
			 headers:{
				admintoken:localStorage.getItem('admin_token')
			 },
			 isProgressVis: false,
			 percentage: 0,
			 fileLoading:false,
    }
  },
  methods:{
    chunkData(option){
      return{
        size: option.fileSize, // 总文件大小
        chunks: option.chunkTotal, // 所有切片数量
        chunk: option.chunkIndex,// 当前切片下标
        md5: option.chunkHash, // 单个切片hash
        filename: option.fileName, // 文件名
        fileHash: option.fileHash // 整个文件hash
      }
    },
	// get(){
	// 	var chars = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
	// 	function generateMixed(n) {
	// 	     var res = "";
	// 	     for(var i = 0; i < n ; i ++) {
	// 	         var id = Math.ceil(Math.random()*35);
	// 	         res += chars[id];
	// 	     }
	// 	     return res;
	// 	}
	// }
	async postImage(option) {
		var time=new Date()
		var y=time.getFullYear()
		var m=time.getMonth()+1
		if(m<10){
			m=0+''+m
		}else{
			m=m
		}
		var d=time.getDate()
		var t=y+''+m+''+d
		console.log(time.getFullYear())
		var date=Date.parse(new Date())
	    try {
	      //构建上传文件参数
	      this.fileLoading = true
	      let self = this
			let client = new OSS({
			  region: oss.region,
			  accessKeyId: oss.accessKeyId,
			  accessKeySecret: oss.accessKeySecret,
			  bucket: oss.bucket,
			})
			let partSize={
				partSize:5*1024*1024
			}
	      let objectName = '/addnews/'+t+'/'+escape(date)+'.'+option.file.name.split('.')[option.file.name.split('.').length-1]
	      // 分片上传文件
	      let result = await client.multipartUpload(objectName, option.file,partSize, {
		     //进度条更新
			 progress: async function(p) {
	          self.percentage = parseInt(p * 100)
	          self.isProgressVis = true
	        }
	      })
		  // if(result.res.requestUrls[0])
	      console.log(result.res.requestUrls[0].split('?')[0])

	  } catch (error) {
		  console.log(error)
	    this.$message.error(error.message)
	  }
	}
    // handleSuccess(response, file, fileList) {
    //   //文件上传成功
    //   console.log(response, file, fileList);
    // },
// 	async postImage(file) {
// 		console.log(file)
// 		try {
// 		    //构建上传文件参数
		
// 		    this.fileLoading = true
// 		    let self = this
// 		    let client = new OSS({
// 		      region: 'oss-cn-beijing',
// 		      accessKeyId: 'LTAI4GGEthRttGZ2HZ6aurLz',
// 		      accessKeySecret: '3VEoZelrm8t8PlWAZm9mz1KyzznOOz',
// 		      bucket: 'qnstore',
// 		      secure: true
// 		    })
// 		    let objectName = '/shop/'+file.file.name
// 		    // 分片上传文件
// 		    let result = await client.multipartUpload(objectName, file.file, {
// 			     //进度条更新
// 				progress: async function(p) {
// 		        self.percentage = parseInt(p * 100)
// 		        self.isProgressVis = true
// 		      }
// 		    })
// 		    // console.log(result)
// 		    // if (result.res.statusCode === 200) {
// 		    //   //改阿里OSS后直接作用OSS路径前辍，不使用系统部署路径
// 		    //   this.playerOptions.sources[0].src = process.env.VUE_APP_OSS_PREFIX + result.name
// 		    //   this.msgSuccess('修改成功' + result.name)
// 		    //   this.$emit('setUrl', result.name, this.file.name, '', '')
// 		    //   this.fileLoading = false
// 		    //   this.isProgressVis = false
// 		    // } else {
// 		    //   this.$message.error('上传失败')
// 		    // }
		
// 		} catch (error) {
// 		  this.$message.error(error.message)
// 		}
// 		// let client = new OSS({
// 		//   region: 'oss-cn-beijing',
// 		//   accessKeyId: 'LTAI4GGEthRttGZ2HZ6aurLz',
// 		//   accessKeySecret: '3VEoZelrm8t8PlWAZm9mz1KyzznOOz',
// 		//   bucket: 'qnstore',
// 		//   secure: true
// 		// })
		
// 	 //  //构建上传文件参数
// 	 //  // this.loading = true
// 	 //  let user = this.$store.getters.name
// 	 //  let self = this
// 	 //  // https://qnstore.oss-cn-beijing.aliyuncs.com/shop/842f67eb610720c8ae7638177f7cbc9a6e64bef8.jpg
// 	 //  let objectName = '/shop/'+file.file.name
	  	
// // async uploadAudio(option) {

// // }

	  
// 	  //获取上传文件所需要的STS Token
// 	  // getStsToken().then(response => {
// 	  //   if (response.code === 200) {
// 	  //     let token = response.data
// 	  //     //直接通过node.js上传
// 	  //     //console.log(token)


// 	  //   } else {
// 	  //     this.msgError('OSS文件上传Token获取不成功！')
// 	  //   }
// 	  //   this.loading = false
// 	  // })
// 	},
},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
 <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.upload-demo{
  text-align: left;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #dedede;
}
</style>
